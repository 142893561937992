import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import Link from "../../components/link";
import WhatWeDoHero from "../../components/what-we-do-hero";
import HowCanWeHelpRight from "../../components/how-can-we-help-right";
import HowCanWeHelpLeft from "../../components/how-can-we-help-left";
import PhotoLeft from "../../components/photo-left";
import CtaBanner from "../../components/cta-banner";
import BeeBanner from "../../components/bee-banner";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import { GoPrimitiveDot } from "react-icons/go";
import SVGPatternedSidebar from "../../components/SVG/patterned-sidebar";
import SVGLcdBgPoly1 from "../../components/SVG/lcd-bg-poly-1";
import SVGLcdBgPoly2 from "../../components/SVG/lcd-bg-poly-2";
import SVGLcdBgPoly3 from "../../components/SVG/lcd-bg-poly-3";
import SVGLcdBgPoly4 from "../../components/SVG/lcd-bg-poly-4";
import SVGFBLcd from "../../components/SVG/flying-bee-lcd";
import PhotoRight from "../../components/photo-right";
import TextOnly from "../../components/text-only";
import styled from "styled-components";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { Container } from "react-bootstrap";
import SVGFBHp1 from "../../components/SVG/flying-bee-hp-1";

const HRBrighton = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "pier" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			clientBaseImg: wpMediaItem(title: { eq: "Bespoke-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			trainingImg: wpMediaItem(title: { eq: "Handbook-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ErImg: wpMediaItem(title: { eq: "Personal-Service-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			rrsImg: wpMediaItem(title: { eq: "Proactive-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ourTeamImg: wpMediaItem(title: { eq: "Our-Team-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			backgroundImg: wpMediaItem(title: { eq: "Home-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			employeeAppraisalsImg: wpMediaItem(
				title: { eq: "Employee-Appraisals-Image" }
			) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const isDarken = true;

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const trainingImg =
		data.trainingImg?.localFile.childImageSharp.gatsbyImageData;
	const ErImg = data.ErImg?.localFile.childImageSharp.gatsbyImageData;
	const clientBaseImg =
		data.clientBaseImg?.localFile.childImageSharp.gatsbyImageData;
	const employeeAppraisalsImg =
		data.employeeAppraisalsImg?.localFile.childImageSharp.gatsbyImageData;
	const ourTeamImg = data.ourTeamImg?.localFile.childImageSharp.gatsbyImageData;
	const backgroundImg =
		data.backgroundImg?.localFile.childImageSharp.gatsbyImageData;
	const rrsImg = data.rrsImg?.localFile.childImageSharp.gatsbyImageData;
	const StyledBackground = styled(BgImage)`
		&::before,
		&::after {
			filter: brightness(
				${({ isDarken }) => {
					return isDarken ? "40%" : "100%";
				}}
			);
		}
	`;
	const pluginImage = getImage(backgroundImg);

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": "https://www.hivehrsolutions.co.uk/locations/hr-brighton/#brighton",
		image: `https://hive-hr.rjmdigital.net/wp-content/uploads/2021/12/pier.png`,
		mpn: "https://www.hivehrsolutions.co.uk/locations/hr-brighton/",
		sku: "https://www.hivehrsolutions.co.uk/locations/hr-brighton/",
		description:
			"We offer companies a full range of HR services in Brighton. From people management to HR support, recruitment, training and procedures.",
		logo: `https://hive-hr.rjmdigital.net/wp-content/uploads/2021/12/HiveHR-Logo-Vertical-2021-GLOSSY-RGB.png`,
		name: "Hive HR Solutions",
		url: "https://www.hivehrsolutions.co.uk",
		brand: {
			"@type": "Organization",
			logo: `https://hive-hr.rjmdigital.net/wp-content/uploads/2021/12/HiveHR-Logo-Vertical-2021-GLOSSY-RGB.png`,
			image: `https://hive-hr.rjmdigital.net/wp-content/uploads/2021/12/pier.png`,
			name: "Hive HR Solutions",
			url: "https://www.hivehrsolutions.co.uk",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `75`,
			ratingValue: "4.9",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: "https://www.hivehrsolutions.co.uk/reviews",
			datePublished: "2022-08-30T17:58:29+00:00",
			reviewBody:
				"My experience in working with Hive HR Solutions was very much a positive one",
			author: {
				"@type": "Organization",
				name: "Voluntary Services Lewisham",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "Hive HR Solutions",
				sameAs: "https://www.hivehrsolutions.co.uk/",
			},
		},
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Locations",
				item: {
					url: `${siteUrl}/locations`,
					id: `${siteUrl}/locations`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Brighton",
				item: {
					url: `${siteUrl}/locations/hr-brighton`,
					id: `${siteUrl}/locations/hr-brighton`,
				},
			},
		],
	};

	if (typeof window !== "undefined") {
		return (
			<Layout>
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>
				<Helmet>
					<script type="application/ld+json">
						{JSON.stringify(serviceSchema)}
					</script>
				</Helmet>

				<GatsbySeo
					title="Brighton HR Consultant | HR Support Brighton | Hive HR"
					description="We offer companies a full range of HR services in Brighton. From people management to HR support, recruitment, training and procedures."
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/locations/hr-brighton`,
						title:
							"HR Consultant Brighton | Brighton HR Support | Hive HR Solutions",
						description:
							"We offer companies a full range of HR services. From people management to HR support, recruitment, training and procedures.",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>
				<section className=" pt-md-4 pt-lg-0">
					<WhatWeDoHero
						hideSHeading
						pos="30%"
						title="Looking for an HR consultant in Brighton?"
						img={
							<div>
								<Row className="g-0 d-lg-block d-none ">
									<Col
										style={{ overflow: "hidden" }}
										className="g-0  pb-0   position-relative"
									>
										<GatsbyImage
											style={{ width: "84%" }}
											className=" tImage  mb-4"
											alt="HR Consultant in Brighton providing HR support"
											image={heroImg}
										/>
										<SVGPatternedSidebar
											style={{ width: "20%", right: "-1rem" }}
											className="position-absolute bg-primary tImage top-0  "
										/>
									</Col>
								</Row>
								<GatsbyImage
									className="w-100 d-lg-none mb-4"
									alt={data.heroImg?.altText}
									image={heroImg}
								/>
							</div>
						}
					/>
				</section>
				<section className=" pt-xl-7 position-relative">
					<SVGLcdBgPoly1
						style={{ zIndex: -2 }}
						className="position-absolute end-0 top--10 d-none d-lg-block "
					/>
					<PhotoRight
						mobileOff={true}
						height="25rem"
						width="38rem"
						cta="Contact Us"
						ctaUrl="/contact-us"
						img={clientBaseImg}
						imgAlt="Hive HR consultants based in Brighton"
						heading="Our extensive client base comprises small and medium-sized businesses in Brighton and East Sussex."
						text={
							<span>
								<p>
									We offer companies a full range of HR services. From
									recruitment, management training, advice on people issues from
									absence management, disciplinary's to redundancies and
									ensuring your HR documentation is up to date and reflects your
									business. 
								</p>
							</span>
						}
					/>
				</section>
				<section className=" position-relative pt-xl-10 pb-lg-6">
					<SVGFBLcd
						style={{ zIndex: -2, top: "-5%", left: "38%" }}
						className="position-absolute  translate-middle-x  d-none d-xl-block "
					/>
					<HowCanWeHelpLeft
						listHeading="Tailored handbooks"
						height="30rem"
						width="33rem"
						img={trainingImg}
						imgAlt="HR consultant providing HR support in the Hive HR office in Brighton"
						heading="HR consultancy and employment law service"
						text={
							<span>
								<p>
									We can help your business stay on the right side of the
									employment law. Our expert and professional human resource and
									legal and health consultants help employers and employees stay
									HR compliant by providing the following services:
								</p>
							</span>
						}
						list={
							<p>
								Let us create a bespoke HR handbook containing your values, ways
								of working and your policies and procedures for you and your
								employees.
							</p>
						}
					/>
				</section>
				<section className=" pt-lg-6 pt-4 position-relative">
					<SVGFBLcd
						style={{ zIndex: -2, top: "-5%", left: "-5%" }}
						className="position-absolute    d-none d-xl-block "
					/>
					<PhotoRight
						height="25rem"
						width="38rem"
						img={rrsImg}
						imgAlt={data.rrsImg?.altText}
						heading="Recruiting the right employees"
						text={
							<span>
								<p>
									We can assist and advise small and medium businesses in
									Brighton to recruit and select the right employees.
								</p>
								<p>
									Our psychometric Brighton HR consultants can help your
									business screen a range of applicants and achieve more success
									in terms of employee retention.
								</p>
							</span>
						}
					/>
				</section>
				<section className=" position-relative pt-xl-5 pb-lg-6">
					<SVGLcdBgPoly2
						style={{ zIndex: -2 }}
						className="position-absolute end-0 top--30  d-none d-lg-block"
					/>

					<PhotoLeft
						idLeft="Restructuring-Left"
						idRight="Restructuring-Right"
						height="25rem"
						width="38rem"
						img={employeeAppraisalsImg}
						imgAlt={data.employeeAppraisalsImg?.altText}
						heading="Restructuring Consultancy"
						text={
							<span>
								<p>
									Achieve your strategic goals by restructuring your company.
								</p>
								<p>
									Our service includes drafting a detailed action plan, support
									with writing a business case review of internal
									communications, consultation support, redundancy calculations
									where appropriate and much more.
								</p>
							</span>
						}
					/>
				</section>
				<section>
					<CtaBanner
						headline="Ready to speak to us"
						btnTxt="Get in touch"
						btnLink="/contact-us"
						gradient={true}
					/>
				</section>

				<section className=" pt-lg-6 py-5 pb-lg-10 position-relative">
					<SVGLcdBgPoly4
						style={{ zIndex: -2 }}
						className="position-absolute start-0 top-10 d-none d-lg-block "
					/>
					<PhotoRight
						height="25rem"
						width="38rem"
						img={ErImg}
						imgAlt={data.ErImg?.altText}
						heading="Employee relations"
						text={
							<span>
								<p>
									We’ve got your back from developing an HR disciplinary policy
									to dealing with grievances, carrying out investigations, and
									arranging and conducting disciplinary and grievance hearings.
								</p>
							</span>
						}
					/>
				</section>
				<section>
					<StyledBackground
						isDarken={isDarken}
						key={isDarken ? `dark` : `light`}
						className="pb-7 pt-xl-10"
						image={pluginImage}
					>
						<Container>
							<Row>
								<Col className="text-white mt-10">
									<h2>
										Small and medium company human resources documentation
									</h2>
									<p>
										Let us remove the hassle of writing your employment
										contracts and generic HR company templates and business
										management documents.
									</p>
								</Col>
							</Row>
						</Container>
					</StyledBackground>
				</section>
				<section className="pb-lg-0 py-5 position-relative">
					<SVGFBHp1 className="position-absolute end-0 top-40 d-none d-lg-block" />
					<TextOnly
						heading="Manage employee data and holiday requests with BreatheHR software"
						text={
							<span>
								<p>
									Do you wish you had an efficient place to store employee files
									and company documents?
								</p>
								<p>
									Do you wish your employees could manage their time off with
									dedicated HR software?
								</p>
							</span>
						}
					/>
				</section>
				<section className="pt-lg-5 mt-lg-7">
					<BeeBanner
						headline="Talk to a Brighton HR consultant today to discuss BreatheHR software."
						btnTxt="Contact Us"
						btnLink="/contact-us"
					/>
				</section>
			</Layout>
		);
	}
};

export default HRBrighton;
